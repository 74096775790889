<template>
    <div class="toolbar_main">
        <section class="blog_page">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="widget">
                            <div class="section-header">
                                <h5 class="heading-design-h5">Politica de Terminos y condiciones</h5>
                            </div>

                            <p class="text-justify">
                                Todas las compras en este sitio web se rigen por los términos y condiciones descritos a continuación, así como por la legislación que se aplique en la República del Perú. Todas las compras, y sus efectos jurídicos, quedarán regidos por estas reglas y sometidas a dicha legislación.
                                Los términos y condiciones contenidos en este instrumento formarán parte de todos los actos y contratos que se ejecuten o celebren mediante los sistemas de oferta y comercialización comprendidos en este sitio web, entre los clientes de <b>{{empresa.pagina_web}}</b>, denominados en adelante “usuarios” de este sitio y <i>{{empresa.razon_social}}</i>, a quien se le denominará en adelante <b>{{empresa.pagina_web}}</b>
                            </p>
              
                            <div id="accordion" role="tablist">
                                <div class="card">
                                    <div class="card-header" role="tab" id="headingOne">
                                        <h5 class="mb-0">
                                            <a data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne"  >
                                                <b>1.¿Qué es este documento? </b>
                                            </a>
                                        </h5>
                                    </div>
                                    
                                    <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div class="card-body text-justify">
                                            Te damos la bienvenida a {{empresa.pagina_web}}, la plataforma de titularidad de <i>{{empresa.razon_social}}</i>, identificada con RUC N° {{empresa.ruc}}, con domicilio en:
                                            <ul>
                                                <li v-for="(val, key) in empresa.sucursales" :key="key">{{val.direccion}}</li>
                                            </ul>
                                            Av. Paseo de la República N° 3220, Distrito de San Isidro, Provincia y Departamento de Lima.
                                            Estos términos y condiciones (en adelante, los “T&C”) regulan el acceso y uso, en Perú, pero desde donde sea que accedas y lo uses, a nuestro sitio web (en adelante también “<b>{{empresa.pagina_web}}</b>” o el “Sitio”).
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header" role="tab" id="headingTwo">
                                        <h5 class="mb-0">
                                            <a class="collapsed" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <b>2. Autorización de uso </b> 
                                            </a>
                                        </h5>
                                    </div>
                                    
                                    <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div class="card-body text-justify">
                                            Los servicios de <b>{{empresa.pagina_web}}</b> están disponibles sólo para aquellos individuos que tengan capacidad legal para contratar, según lo dispuesto por la legislación peruana vigente. Si una persona no tiene capacidad legal para contratar, debe abstenerse de utilizar los servicios ofrecidos en este sitio.
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header" role="tab" id="headingThree">
                                        <h5 class="mb-0">
                                            <a class="collapsed" data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <b>3. Tu cuenta </b> 
                                            </a>
                                        </h5>
                                    </div>
                                    
                                    <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion">
                                        <div class="card-body">
                                            Para acceder el Sitio debes estar registrado previamente. De esta manera, podemos entregarte una experiencia de compra personalizada y ajustada a tus preferencias e intereses, y podrás hacer seguimiento de tu pedido, descargar la boleta y otros documentos asociados, gestionar solicitudes de manera sencilla, y efectuar pagos de manera más expedita al tener registrados tus medios de pago y tus direcciones de despacho, si es que los registras, entre otros beneficios.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';

    export default {
        name: 'politicas',
        computed:{
            ...mapState(['empresa', 'mi_cuenta', 'sucursal_seleccionada']),
        },
        created(){
            this.loadObjPerfilUsuario();
        },
        mounted(){
            this.loadObjPerfilUsuario();
            document.title = "Términos y Condiciones | "+((this.empresa.abreviatura)?this.empresa.abreviatura:"-");
        },
        data(){
            return{
                misDatos:{},
            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjPerfilUsuario']),
            
        },
    }
</script>